<template>
    <div class="info-window mb-3" >
        <h3 class="mb-2 info-window-header">{{ title }}</h3>
        <p class="info-window-text text-pre-wrap" v-html="text"></p>
    </div>
</template>
<script>
export default {
    name: "DateInputInfo",
    props: {
        show: {
            type: Boolean,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true,
            default: "Put your information here. ;)"
        }
    }
}
</script>
<style scoped>
.info-window {
    border: 2px solid darkgreen;
    border-radius: 2px;
    padding: 5px;
}
.info-window-header {
    border-bottom: 1px solid darkgray;
}
.info-window-text {
    margin-top: 10px;
    font-size: 14px;
}
</style>
