<template>
    <div>
    <v-row>
        <v-col>
            <v-sheet height="650" outlined>
                <div class="d-flex flex-row align-center" v-if="currentSchedule">
                    <div>
                        <v-card-title>"{{ currentSchedule['name'] }}"</v-card-title>
                    </div>

                    <div class="d-flex flex-row mr-8" v-if="Object.keys(showIo).length > 0">
                        <!-- TODO do color calculation later -->
                        <v-avatar
                            v-for="schedule in currentSchedule.schedules"
                            :key="schedule.io_id"
                            :style="{cursor: 'pointer'}"
                            class="px-4 mx-4"
                            :color="calcColor(schedule.io_id)"
                            :ref="schedule.io_id"
                            @click="checkChangeButton"
                        >
                            <span class="white--text text-h6 px-3">{{ schedule.io_id }}</span>
                        </v-avatar>

                    </div>
                    <div>
                        <div class="mx-4">
                            {{ $t('schedule.showRiseSet') }} <span>({{ $t('schedule.rise') }}: {{ sunriseset.sunrise }} -  {{ $t('schedule.set') }}: {{ sunriseset.sunset }} )</span>
                        </div>
                    </div>
                </div>
                <v-calendar
                    ref="calendar2"
                    v-model="todayShadow"
                    :now="today2"
                    :event-color="getEventColor"
                    :event-ripple="false"
                    :events="events"
                    :weekdays="weekDays"
                    color="accent"
                    type="week"
                    @change="getEvents"
                    @click:event="showEvent"
                    :short-intervals="false"
                    interval-height="20"
                    :locale="$i18n.locale"
                >
                    <template v-slot:event="{ event }">
                        <div>
                            <div class="text-center"><strong>{{ event.name }}</strong></div>
                            <div class="text-center" v-if="eventLength(event) > 2">IO : {{ event.io_id }}</div>
                        </div>
                    </template>
                    <template v-if="true" v-slot:day-body="{ timeToY }">

                        <div
                            :style="{ top: timeToY(sunriseset.sunrise ? sunriseset.sunrise : '0:00') + 'px' }"
                            class="v-current-time"
                        ></div>

                        <div

                            :style="{ top: timeToY(sunriseset.sunset ? sunriseset.sunset : '23.59') + 'px'}"
                            class="v-current-time"
                        ></div>
                    </template>
                </v-calendar>

                <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                    top
                >
                    <v-card
                        color="grey lighten-4"
                        min-width="350px"
                    >
                        <v-toolbar
                            :color="selectedEvent.color"
                            dark
                        >
                            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                        </v-toolbar>
                        <v-card-title class="mx-auto">
                            {{ $t('menu.buttons.schedule') }} UID : {{ selectedEvent.schedule_uid }}
                        </v-card-title>
                        <v-card-subtitle>
                            <div>
                                IO : {{ selectedEvent.io_id }}
                            </div>
                            <div>
                                {{ $t('main.profile') }} : {{ selectedEvent.profile_id }}
                            </div>
                        </v-card-subtitle>
                        <switch-profile-card :profile="selectedEvent" :switch-number="parseInt(selectedEvent.switch_id - 1)" :scheduleEvent="true" /> <!-- was :profile="selectedEvent" -->
                    </v-card>
                </v-menu>
            </v-sheet>
            <div v-if="currentSchedule">
                <v-btn color="info" @click="sendData" :disabled="!hasSchedule">{{ $t('schedule.editSchedule') }}</v-btn>
            </div>

        </v-col>
    </v-row>
    </div>
</template>

<script>
import SunCalc from 'suncalc';
import {getWeekNumber, timeConv} from "@/services/dateConverter";
import dayjs from 'dayjs';
import SwitchProfileCard from "@/components/Schedule/SwitchProfileCard";
import {arrayToString} from "@/services/mainHelpers";

const colorOptions = [
    { text: 'Indigo', value: 'indigo' },
    { text: 'Light Blue', value: 'light-blue' },
    { text: 'Purple', value: 'purple' },
    { text: 'Light Green', value: 'light-green' },
    { text: 'Deep Orange', value: 'deep-orange' },
    { text: 'Amber', value: 'amber' },
    { text: 'Primary', value: 'primary' },
    { text: 'Secondary', value: 'secondary' },
    { text: 'Accent', value: 'accent' },
    { text: 'Red', value: 'red' },
    { text: 'Pink', value: 'pink' },
    { text: 'Deep Purple', value: 'deep-purple' },
    { text: 'Blue', value: 'blue' },
    { text: 'Cyan', value: 'cyan' },
    { text: 'Teal', value: 'teal' },
    { text: 'Green', value: 'green' },
    { text: 'Lime', value: 'lime' },
    { text: 'Yellow', value: 'yellow' },
    { text: 'Orange', value: 'orange' },
    { text: 'Brown', value: 'brown' },
    { text: 'Blue Gray', value: 'blue-gray' },
    { text: 'Gray', value: 'grey' },
    { text: 'Black', value: 'black' },
];
const colors = colorOptions.map((el) => el.value);

export default {
    name: "DaySchedule",
    components: {
        SwitchProfileCard
    },
    props: {
        today2: {
            type: String,
            required: false
        },
        gpsLocation: {
            type: Object,
            required: false
        },
        scheduleProfile: {
            type: Object,
            required: false,
            default: null
        }
    },
    async mounted() {
        this.$refs.calendar2.checkChange();
        this.calculateSunriseSet();
        if(this.$store.getters['auth/StateActiveOrganisation']){
            if (this.scheduleProfile) {
                this.generateIoObjects();
                this.createWeekInfo2()
            }
          else {
            if (this.$route.query.schedule_id) {
              await this.$store.dispatch('sched/searchSchedule', {
                schedule_id: this.$route.query.schedule_id,
                schedule_version: this.$route.query.schedule_version
              });
              this.tempSchedule = await this.$store.getters['sched/getSingleSchedule'];
              this.generateIoObjects();
            } else {
                // Object.keys(this.showIo).forEach((io) => this.showIo[io].show = false);
                // Make no IO avatars
            }
            this.createWeekInfo2();
          }
        } else {
          Object.keys(this.showIo).forEach((io) => this.showIo[io].show === false);
        }
        this.ready = true;
    },
    data(){
        return {
            weekDays: [1, 2, 3, 4, 5, 6, 0],
            value: '',
            events: [],
            thisWeek: {
                start: '',
                end: ''
            },
            ready: false,
            totalHoursWeek: 0,
            toggle: false,
            sunriseset: {
                sunrise: '',
                sunset: '',
                toggle: false
            },
            selectedOpen: false,
            selectedElement: null,
            selectedEvent: {},
            showIo: {
                // 'out.1': {
                //     id: 0,
                //     external: 'out.1',
                //     color: 'pink',
                //     show: true
                // },
            },
            tempSchedule: null,
        };
    },
    computed: {
        cal() {
            return this.ready ? this.$refs.calendar2 : null;
        },
        getSunrise(){
            return this.sunriseset.sunrise;
        },
        getSunset(){
            return this.sunriseset.sunset;
        },
        getWeekNumber(){
            return getWeekNumber(this.today2);
        },
        todayShadow: {
            get(){
                if (!this.today2) return null;
                return this.today2;
            },
            set(newValue) {
                // nothing
            }
        },
        currentSchedule() {
            return this.scheduleProfile || this.tempSchedule || {};
        },
        hasSchedule(){
            return Object.keys(this.currentSchedule).length > 0;
        },
    },
    methods: {
        timeFormat(time, kind){
            let result;
            const sign = time < 0 ? '-' : '+';
            if (this.$i18n.locale === 'en'){
                switch(kind) {
                    case 'Absolute':
                        result = `${time.substr(0,5)}`;
                        break;
                    case 'Sunrise':
                    case 'Sunset':
                        if (time === 0) {
                            result = `${kind}`;
                        } else {
                            result = `${kind} ${sign} ${Math.abs(time)}s`;
                        }
                        break;
                }
            } else {
                let localeKind;
                switch(kind) {
                    case 'Absolute':
                        result = `${time.substr(0,5)}`;
                        break;
                    case 'Sunrise':
                        localeKind = 'Zonsopkomst';
                        if(time === 0) result = `${localeKind}`;
                        else result = `${localeKind} ${sign} ${Math.abs(time)}s`;
                        break;
                    case 'Sunset':
                        localeKind = 'Zonsondergang';
                        if(time === 0) result = `${localeKind}`;
                        else result = `${localeKind} ${sign} ${Math.abs(time)}s`;
                        break;
                }
            }
            return result;
        },
        sendData() {
            const scheduleObject = {
                schedule_id: this.currentSchedule.id,
                schedule_version: this.currentSchedule.versionId,
                name: this.currentSchedule.scheduleName,
                io_id: 0,
                profile_id: 0,
                scheduleInputType: 'edit'
            };
            this.$router.push({
                name: 'schedNew',
                params: { crudType: scheduleObject.scheduleInputType },
                query: { schedule_id: `${scheduleObject.schedule_id}`,
                    schedule_version: `${scheduleObject.schedule_version}`,
                    io_id: `${scheduleObject.io_id}`,
                    profile_id: `${scheduleObject.profile_id}`,
                    name: scheduleObject.name }});
        },
        getTranslatedweekDays(weekArray){
            let tempArr = [];
            weekArray.forEach((day) => {
                tempArr.push(this.$t(`schedule.weekdays.${day}`));
            });
            return tempArr;
        },
        checkChangeButton(event) {
          if (this.$store.getters['auth/StateActiveOrganisation']){
            const ionr = event.target.textContent.toLowerCase();
            this.showIo[ionr].show = !this.showIo[ionr].show;
            this.createWeekInfo2();
          }
        },
        getEventColor(event) {
            return event.color;
        },
        showEvent({nativeEvent, event}){
            const open = () => {
                this.selectedEvent = event;
                this.selectedElement = nativeEvent.target;
                this.selectedOpen = true;
            };
            if (this.selectedOpen) {
                this.selectedOpen = false;
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }
            nativeEvent.stopPropagation();
        },
        getEvents (event) {
            this.thisWeek.start = event.start;
            this.thisWeek.end = event.end;
            const tempObj = { ...this.thisWeek };
            this.$store.commit('sched/changeWeekInfo', tempObj);
            this.calculateSunriseSet();
            if (this.scheduleProfile) this.createWeekInfo2();
        },
        calculateSunriseSet() {
            const today2Date = new Date(`${this.today2}T12:00:00`);
            const times = SunCalc.getTimes(today2Date, this.gpsLocation.lat, this.gpsLocation.lng, 0);
            this.sunriseset.sunrise = timeConv(times.sunrise);
            this.sunriseset.sunset = timeConv(times.sunset);
        },
        createWeekInfo2() {
            let timedDate = false;
            let startDateObj;
            let endDateObj;
            const dateFormatStr = "YYYY-MM-DD HH:mm:ss";
            const timeFormatStr = "HH:mm";

            let weekStart = dayjs(`${this.thisWeek.start.date} 12:00:00`, dateFormatStr).add(-1, 'days');
            const weekEnd = dayjs(`${this.thisWeek.end.date} 12:00:00`, dateFormatStr).add(2, 'days');

            this.events = [];

            while (weekStart.isBefore(weekEnd)) {

                const riseSetTimes = SunCalc.getTimes(weekStart.toDate(), this.gpsLocation.lat, this.gpsLocation.lng, 0);
                const sunrise = timeConv(riseSetTimes.sunrise, 1);
                const sunset = timeConv(riseSetTimes.sunset, 1);
                const startDate = weekStart.format('YYYY-MM-DD');



                for (let io of this.currentSchedule.schedules){
                    // make sure we can still find the io names after the API changed
                    const io_id = Object.keys(this.showIo).find(key => this.showIo[key].external === io.io_id)

                    if (this.showIo[io_id].show) {
                        let profile_id = 0;
                        for (let profile of io.profiles) {

                            if (profile.startDate) {
                                timedDate = true;
                                const thisYear = weekStart.format('YYYY');
                                startDateObj = dayjs(`${thisYear}-${profile.startDate} 12:00:00`, dateFormatStr);
                                endDateObj = dayjs(`${thisYear}-${profile.endDate} 12:00:00`, dateFormatStr);
                            }
                            let switch_id = 0;
                            for (let switch1 of profile.switches) {

                                let startTime;
                                let startTimeObj;
                                let startString;
                                if (switch1.on.kind === 'Absolute') {
                                    startTime = switch1.on.time;
                                    // startString = `${startTime.substr(0,5)} (ABS)`;
                                    startString = this.timeFormat(startTime, switch1.on.kind);
                                    startTimeObj = dayjs(`${startDate} ${startTime}`, dateFormatStr)
                                } else if (switch1.on.kind === 'Sunrise') {
                                    const tmp = switch1.on.offset;
                                    startString = this.timeFormat(tmp, switch1.on.kind);
                                    // startString = `${tmp} (RIS)`;
                                    startTimeObj = dayjs(`${startDate} ${sunrise}`, dateFormatStr).add(switch1.on.offset, 'second');
                                    startTime = startTimeObj.format(timeFormatStr);
                                } else if (switch1.on.kind === 'Sunset') {
                                    const tmp = switch1.on.offset;
                                    startString = this.timeFormat(tmp, switch1.on.kind);
                                    // startString = `${tmp} (SET)`;
                                    startTimeObj = dayjs(`${startDate} ${sunset}`, dateFormatStr).add(switch1.on.offset, 'second');
                                    startTime = startTimeObj.format(timeFormatStr);
                                }

                                let currentDay = dayjs(`${startDate} ${startTime}`, dateFormatStr);
                                if (currentDay.isBefore(dayjs(`${startDate} 12:00:00`, dateFormatStr))) {
                                    currentDay = currentDay.add(-1, 'day');
                                }

                                let switchEndTime;
                                let endString;
                                if (switch1.off.kind === 'Absolute') {
                                    const tmp = switch1.off.time;
                                    //endString = `${tmp.substr(0,5)} (ABS)`;
                                    endString = this.timeFormat(tmp, switch1.off.kind);
                                    switchEndTime = switch1.off.time;
                                } else if (switch1.off.kind === 'Sunrise') {
                                    const tmp = switch1.off.offset;
                                    endString = this.timeFormat(tmp, switch1.off.kind);
                                    // endString = `${tmp} (RIS)`;
                                    switchEndTime = dayjs(`${startDate} ${sunrise}`, dateFormatStr).add(switch1.off.offset, 'second').format(timeFormatStr);
                                } else if (switch1.off.kind === 'Sunset') {
                                    const tmp = switch1.off.offset;
                                    endString = this.timeFormat(tmp, switch1.off.kind);
                                    // endString = `${tmp} (SET)`;
                                    switchEndTime = dayjs(`${startDate} ${sunset}`, dateFormatStr).add(switch1.off.offset, 'second').format(timeFormatStr);
                                }

                                const endTime = dayjs(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm:ss').isBefore(dayjs(`${startDate} ${switchEndTime}`, dateFormatStr)) ?
                                    dayjs(`${startDate} ${switchEndTime}`, dateFormatStr) :
                                    dayjs(`${startDate} ${switchEndTime}`, dateFormatStr).add(1, 'day');

                                let event = {
                                    name: this.currentSchedule.name,
                                    day: currentDay.format('ddd').toLowerCase(),
                                    schedule_uid: this.currentSchedule.uid,
                                    switch_id: switch_id + 1,
                                    io_id: io.io_id,
                                    profile_id: profile_id + 1,
                                    start: startTimeObj.format(dateFormatStr),
                                    end: endTime.format(dateFormatStr),
                                    color: colors[io.io_id.split(".")[1] - 1],
                                    display: {
                                        onStr: startString,
                                        offStr: endString,
                                        days: arrayToString(this.getTranslatedweekDays(profile.days)), // arrayToString(profile.days),
                                        startDate: profile.startDate ? profile.startDate : '',
                                        endDate: profile.endDate ? profile.endDate : '',
                                    }
                                };

                                if (profile.days.includes(event.day)) {
                                    if (!timedDate || (timedDate && (startTimeObj.isAfter(startDateObj) && startTimeObj.isBefore(endDateObj)))){
                                        this.events.push(event);
                                    }

                                }
                                switch_id++;
                            }
                            timedDate = false;
                            profile_id++;
                        }
                    }
                }


                weekStart = dayjs(weekStart).clone().add(1, 'day');
            }
        },
        eventLength(event){
            const startDate = new Date(event.start);
            const endDate = new Date(event.end);
            const length = (endDate - startDate) / (1000 * 60 * 60); // length in hours
            return Math.floor(length);
        },
        generateIoObjects() {
            this.currentSchedule.schedules.forEach((schedule) => {
                const io_id = schedule.io_id;
                const id = parseInt(io_id.split(".")[1], 10) - 1;
                this.showIo[io_id] = {
                    id: id,
                    external: schedule.io_id,
                    color: colors[id],
                    show: true
                };
            });
        },
        calcColor(io_id){
            if (io_id){
                const id = Object.keys(this.showIo).find((el) => el === io_id);
                const idx = this.showIo[id].id;
                return this.showIo[id].show ? colors[idx] : colors[21]  // is gray
            }
            return colors[21];  // is gray
        }
    },
}
</script>

<style lang="scss" scoped>
.v-current-time {
    height: 2px;
    background-color: #ea4335;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;

&.first::before {
     content: '';
     position: absolute;
     background-color: #ea4335;
     width: 12px;
     height: 12px;
     border-radius: 50%;
     margin-top: -5px;
     margin-left: -6.5px;
 }
}
.v-event-timed {
    user-select: none;
    -webkit-user-select: none;
}
.v-event-delete {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 6px;
    height: 12px;
}
</style>
